import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  private baseUrl = 'http://api.quotation.connextphilippines.com/api';

  constructor(
    private http: HttpClient,
  ) { }

  getBranch() {
    return this.http.get(`${this.baseUrl}/listbranch`);
  }

  getbranchList() {
    return this.http.get(`${this.baseUrl}/listbranch`);
  }

  createBranch(data) {
    return this.http.post(`${this.baseUrl}/createbranch`, data);
  }

  companyList() {
    return this.http.get(`${this.baseUrl}/listcompany`);
  }

  updateBranch(data) {
    return this.http.post(`${this.baseUrl}/getbranchid`, data);
  }

  updateBranchId(data) {
    return this.http.put(`${this.baseUrl}/updatebranch/${data.id}`, data);
  }
}
