import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminFormService {
  private baseUrl = 'http://api.quotation.connextphilippines.com/api';

  constructor(
    private http: HttpClient,
  ) { }

  storeData(data) {
    return this.http.post(`${this.baseUrl}/createadminuser`, data);
  }

  getUserAdmin(data) {
    return this.http.post(`${this.baseUrl}/listadminuser`, data);
  }
}
