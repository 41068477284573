import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  private baseUrl = 'http://api.quotation.connextphilippines.com/api';

  constructor(
    private http: HttpClient,
  ) { }

  storeAgent(data) {
    return this.http.post(`${this.baseUrl}/createagentuser`, data);
  }

  getRequest() {
    return this.http.get(`${this.baseUrl}/accountingtable`);
  }

  getAgent(data) {
    return this.http.post(`${this.baseUrl}/listagentuser`, data);
  }

  getAgentCount() {
    return this.http.get(`${this.baseUrl}/getagentcount`);
  }

  deactivateUser(data) {
    return this.http.put(`${this.baseUrl}/deactivateagentuser/${data}`, data);
  }

  activateUser(data) {
    return this.http.put(`${this.baseUrl}/activateagentuser/${data}`, data);
  }

  requestUser(data) {
    return this.http.put(`${this.baseUrl}/requestagentuser/${data}`, data);
  }

  declineAccount(data) {
    return this.http.put(`${this.baseUrl}/declinedagent/${data.id}`, data);
  }
}
